
import { defineComponent } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'AboutUs',
  components: {
    ItemHeader,
    ItemWrapper,
  },
  setup() {
    const { t } = useI18n();
    const content = [
      {
        prefix: t('aboutUs.prefix1'),
        content: t('aboutUs.content3'),
      },
      {
        prefix: t('aboutUs.prefix2'),
        content: t('aboutUs.content4'),
      },
      {
        prefix: t('aboutUs.prefix3'),
        content: t('aboutUs.content5'),
      },
      {
        prefix: t('aboutUs.prefix4'),
        content: t('aboutUs.content6'),
      },
    ];
    return {
      t,
      content,
    };
  },
});
