<template>
  <item-wrapper>
    <item-header :title="t('aboutUs.aboutUs')" />
    <main>
      <div class="about-top">
        <h5>{{ t("aboutUs.title1") }}</h5>
        <p>{{ t("aboutUs.content1") }}</p>
      </div>
      <div class="about-image">
        <img src="images/aboutus/about.png" alt="12 months warrantly" />
      </div>
      <div class="about-down">
        <h5>{{ t("aboutUs.title2") }}</h5>
        <p>{{ t("aboutUs.content2") }}</p>
        <div class="about-down-content" v-for="i in content" :key="i">
          <p class="font-middle"> {{ i.prefix  }}&nbsp;</p>
          <p class="font-regular">{{ i.content }}</p>
        </div>
      </div>
    </main>
  </item-wrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'AboutUs',
  components: {
    ItemHeader,
    ItemWrapper,
  },
  setup() {
    const { t } = useI18n();
    const content = [
      {
        prefix: t('aboutUs.prefix1'),
        content: t('aboutUs.content3'),
      },
      {
        prefix: t('aboutUs.prefix2'),
        content: t('aboutUs.content4'),
      },
      {
        prefix: t('aboutUs.prefix3'),
        content: t('aboutUs.content5'),
      },
      {
        prefix: t('aboutUs.prefix4'),
        content: t('aboutUs.content6'),
      },
    ];
    return {
      t,
      content,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";

main {
  @include flex-center();
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 61px;
  h5 {
    font-size: 20px;
    font-weight: bold;
    color: #1c1c1c;
    line-height: 24px;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    color: #545454;
    line-height: 35px;
    margin-bottom: 0;
  }

  .about-image {
    width:100%;
    display:flex;
    flex-direction:row-reverse;
    margin:56px 400px 106px 0;
    img {
      width: 219px;
      height: 231px;
    }
  }
  .about-down {
    margin-bottom: 110px;
    p {
      margin-bottom: 20px;
    }
    .about-down-content {
      display:flex;
      font-size: 18px;
      line-height: 35px;
      p{
        font-size: 18px;
        font-weight: 400;
        color: #545454;
        line-height: 35px;
        margin-bottom:0;
      }
      .font-middle {
        font-weight: 500;
      }
      .font-regular {
        font-weight: 400;
      }
    }
  }
}
</style>
